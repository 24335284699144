import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './auth/Login';
import Maintenance from './utility/Maintenance';
import LearnerRoutes from './learners/LearnerRoutes';
import AdminRoutes from './customer/AdminRoutes';
import SARoutes from './superadmin/SARoutes';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import AdminGuidedTour from './customer/AdminGuidedTour';
import GuidedTour from './learners/learnings/GuidedTour';
import SuperAdminGuidedTour from './superadmin/SuperAdminGuidedTour';
import GetQuote from './contactus/GetQuote';
import ThankYou from './contactus/ThankYou';

AOS.init(); // Initialize AOS

function App() {
  // document.documentElement.style.setProperty('--secondary-color', "#ffed4a");
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="/maintenance" element={<Maintenance/>} />
          <Route path="/adminguidedtour" element={<AdminGuidedTour/>} />
          <Route path="/learnerguidedtour" element={<GuidedTour/>} />
          <Route path="/saguidedtour" element={<SuperAdminGuidedTour/>} />
          <Route path="*" element={<LearnerRoutes/>} />
          <Route path="/admin/*" element={<AdminRoutes/>} />
          <Route path="/superadmin/*" element={<SARoutes/>} />
          <Route path="/getquote" element={<GetQuote/>} />
          <Route path="/thankyou" element={<ThankYou/>} />
        </Routes>
      </Router>
      <ToastContainer position="top-center"/>
    </>
  );
}

export default App;
