import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getAvgLearningTime } from '../../services/EvaluationService';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { getRandomHexColor } from '../../services/CommonService';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);


export default function AvgLearningReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [data, setData] = useState([]);
    const [report, setReport] = useState({
        labels: [],
        datasets: [
          {
            label: 'Avg Learning Time',
            data: [],
            backgroundColor: [],
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
    });
    const optionsAvgLearningTime = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let course_name = data[index].course_name;
                    let avg_learning_time = Math.round(data[index].learning_sec/(60*data[index].total_users));
                    let total_students = data[index].total_users;
                    let total_time = Math.round(data[index].learning_sec/60)
                    return [
                        "Technology: "+course_name,
                        "Avg Learning Time: "+avg_learning_time,
                        "Total Users Learned: "+total_students,
                        "Total Time (Minutes): "+total_time
                    ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            getLearningTimeData();
        }
    },[]);

    const getLearningTimeData = async () => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            setLoading(true);
            const response = await getAvgLearningTime(userDetails.token);
            
            if(!response.error){
                setData(response.data);
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: "Avg Learning Time",
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.learning_sec === undefined || data1.learning_sec === null){
                        data.datasets[0].data.push(0);
                    }else{
                        data.datasets[0].data.push(Math.round(data1["learning_sec"]/(60*data1["total_users"])));
                    }
                    data.labels.push(data1.course_name);
                    data.datasets[0].backgroundColor.push("#C13F63");
                }
                setReport(data);
            }else{
                toast.error("Some error occurred while loading data!");
            }
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-5 py-10 lg:px-12 px-6'>
                    <h3 className='text-2xl text-secondary inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Avg Learning Time</h3>
                    <div>
                        <div className='mt-6'>
                                <div className='barg-graph-container'>
                                    <Bar data={report} options={optionsAvgLearningTime} />
                                </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}