import React, {useEffect, useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateUserCol, storeUserDetails } from '../../services/UserService';


export default function GuidedTour() {
    const navigate = useNavigate();
    const [comp, setComp] = useState(false);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }
        else if(userDetails.user.is_first_time_login){
            updateFirstTimeLogin(userDetails.token);
        }
    },[]);

    const updateFirstTimeLogin = async (token) => {
        let data = {col_name: "is_first_time_login", value: false};
        const response = await updateUserCol(data, token);
    }

    const handleSlideChange = (swiper) => {
        swiper.activeIndex === 4 ? setComp(true) : setComp(false);
    };

    const redirectToDashboard = async () => {
        sessionStorage.setItem("showIntroVideo", true);
        const userDtl = getUserDetails();
        userDtl.user.is_first_time_login = false;
        storeUserDetails(userDtl);
        navigate("/learner/dashboard")
    };

    return (
        <div className='lg:h-screen bg-center bg-no-repeat bg-cover flex items-center justify-center py-[8%] md:py-[8%] lg:py-0' style={{backgroundImage : "url('../../images/bg-tour.webp')"}}>
            <div className='bg-white w-[86%] xl:w-[92%] h-[84%] xl:h-[84%] lg:h-[80%] py-6 lg:py-8 xl:py-12 px-6 lg:px-8 xl:px-20'>
                <div className='relative lg:h-full'>
                    <div className='q-logo lg:absolute top-0 left-0'>
                        <img className='w-24 md:w-32 mx-auto' src='../images/b-qlite-logo.svg' alt='qlite logo'/>
                    </div>
                    <div className='tour-slider-wrap lg:h-full'>
                        <div className='tour-slider overflow-hidden lg:h-full'>
                            <Swiper
                            onSlideChange={handleSlideChange}
                            spaceBetween={0}
                            effect={'fade'}
                            slidesPerView={1}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[EffectFade, Navigation, Pagination]}
                            className='overflow-visible lg:h-full'
                            >
                                <SwiperSlide>
                                    <div className='flex lg:flex-row flex-col-reverse lg:h-full pb-16 lg:pb-0'>
                                        <div className='lg:w-1/2 xl:w-2/5 lg:h-full lg:pr-8 xl:pr-12'>
                                            <div className='tour-img-box lg:h-full lg:pt-16'>
                                                <img className='h-full mx-auto xl:ml-0' src='../images/screenshots_0.png' alt=''/>
                                            </div>
                                        </div>
                                        <div className='lg:w-1/2 xl:w-3/5'>
                                            <div className='tour-text pt-8 lg:pt-20 lg:pb-8 pb-4'>
                                                <h2 className='text-xl md:text-4xl lg:text-3xl 2xl:text-5xl text-black inter-700 text-center lg:text-left'>Your  courses are listed in <br className='hidden md:inline-block' />“Dashboard” and <br className='hidden md:inline-block' />“My Courses” screens</h2>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='flex lg:flex-row flex-col-reverse lg:h-full pb-16 lg:pb-0'>
                                        <div className='lg:w-1/2 xl:w-2/5 lg:h-full lg:pr-8 xl:pr-12'>
                                            <div className='tour-img-box lg:h-full lg:pt-16'>
                                                <img className='h-full mx-auto xl:ml-0' src='../images/screenshots_1.png' alt=''/>
                                            </div>
                                        </div>
                                        <div className='lg:w-1/2 xl:w-3/5'>
                                            <div className='tour-text pt-8 lg:pt-20 lg:pb-8 pb-4'>
                                                <h2 className='text-xl md:text-4xl lg:text-3xl 2xl:text-5xl text-black inter-700 text-center lg:text-left'>Clicking a Course card <br className='hidden md:inline-block' />will show you <br className='hidden md:inline-block' />the modules.</h2>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='flex lg:flex-row flex-col-reverse lg:h-full pb-16 lg:pb-0'>
                                        <div className='lg:w-1/2 xl:w-2/5 lg:h-full lg:pr-8 xl:pr-12'>
                                            <div className='tour-img-box lg:h-full lg:pt-16'>
                                                <img className='h-full mx-auto xl:ml-0' src='../images/screenshots_2.png' alt=''/>
                                            </div>
                                        </div>
                                        <div className='lg:w-1/2 xl:w-3/5'>
                                            <div className='tour-text pt-8 lg:pt-20 lg:pb-8 pb-4'>
                                                <h2 className='text-xl md:text-4xl lg:text-3xl 2xl:text-5xl text-black inter-700 text-center lg:text-left'>Clicking a Module or <br className='hidden md:inline-block' />Module will load the <br className='hidden md:inline-block' />learning content.</h2>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='flex lg:flex-row flex-col-reverse lg:h-full pb-16 lg:pb-0'>
                                        <div className='lg:w-1/2 xl:w-2/5 lg:h-full lg:pr-8 xl:pr-12'>
                                            <div className='tour-img-box lg:h-full lg:pt-16'>
                                                <img className='h-full mx-auto xl:ml-0' src='../images/screenshots_3.png' alt=''/>
                                            </div>
                                        </div>
                                        <div className='lg:w-1/2 xl:w-3/5'>
                                            <div className='tour-text pt-8 lg:pt-20 lg:pb-8 pb-4'>
                                                <h2 className='text-xl md:text-4xl lg:text-3xl 2xl:text-5xl text-black inter-700 text-center lg:text-left'>You can take the <br className='hidden md:inline-block' />Pre learning/ Post <br className='hidden md:inline-block' />learning assessments to <br className='hidden md:inline-block' /> test your knowledge.</h2>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='flex lg:flex-row flex-col-reverse lg:h-full pb-16 lg:pb-0'>
                                        <div className='lg:w-1/2 xl:w-2/5 lg:h-full lg:pr-8 xl:pr-12'>
                                            <div className='tour-img-box lg:h-full lg:pt-16'>
                                                <img className='h-full mx-auto xl:ml-0' src='../images/screenshots_4.png' alt=''/>
                                            </div>
                                        </div>
                                        <div className='lg:w-1/2 xl:w-3/5'>
                                            <div className='tour-text pt-8 lg:pt-20 lg:pb-8 pb-4'>
                                                <h2 className='text-xl md:text-4xl lg:text-3xl 2xl:text-5xl text-black inter-700 text-center lg:text-left'>You can ask the chat-bot, <br className='hidden md:inline-block' />in case you have any <br className='hidden md:inline-block' />doubt</h2>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className='skip-tour absolute 2xl:left-[56%] xl:left-[60%] lg:left-[80%] left-1/2 xl:-translate-x-0 -translate-x-1/2 lg:bottom-8 bottom-0 z-10'>
                            <a onClick={() => redirectToDashboard()} className='cursor-pointer lg:text-xl text-base text-secondary hover:text-tertiary inter-600 underline underline-offset-4 transition duration-300'>{comp? 'Complete Tour':'Skip Tour'}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}