import { API_URL } from "../utility/constants";

export async function getEvaluationReport(token) {
    try{
        const response = await fetch(API_URL+"customer/evaluatioReport/getEvaluationReport", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCohortEvaluationReport(token) {
    try{
        const response = await fetch(API_URL+"customer/cohortReport/getAllCohortEvaluationReport", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCohortEvaluationReport(token, cohort_id) {
    try{
        const response = await fetch(API_URL+"customer/cohortReport/getCohortEvaluationReport?cohort_id="+cohort_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCohortLearnerReport(token, cohort_id) {
    try{
        const response = await fetch(API_URL+"customer/cohortReport/getLearnerReportByCohort?cohort_id="+cohort_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCourseConsumedReportData(token) {
    try{
        const response = await fetch(API_URL+"customer/learningEvaluatioReport/learningSummary", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCourseReportData(token) {
    try{
        const response = await fetch(API_URL+"customer/learningEvaluatioReport/learningDetailV2", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAvgLearningTime(token) {
    try{
        const response = await fetch(API_URL+"customer/learningEvaluatioReport/avgLearningTime", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getUserReport(token, user_id) {
    try{
        const response = await fetch(API_URL+"customer/userReport/getUserReport?user_id="+user_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};