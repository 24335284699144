import React, { useState, useEffect } from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateUserCol, storeUserDetails } from '../../services/UserService';
import { changePassword } from '../../services/ProfileService';

export default function LearnerResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordChangeData, setPasswordChangeData] = useState({ oldPassword: '', newPassword: '', confirmNewPassword: '' });
  const [showPassword, setShowPassword] = useState({ oldPassword: false, newPassword: false, confirmNewPassword: false });
  const [userDetails, setUserDetails] = useState({ user: { need_password_change: false } });
  useEffect(() => {
    const userDetails = getUserDetails();
    if (userDetails.token === undefined) {
      navigate('/login');
    } else {
      setUserDetails(userDetails);
    }
  }, []);

  const handleShowPassword = (type) => {
    if (type === 'oldPassword') {
      setShowPassword({ oldPassword: !showPassword.oldPassword, newPassword: showPassword.newPassword, confirmNewPassword: showPassword.confirmNewPassword });
    } else if (type === 'newPassword') {
      setShowPassword({ oldPassword: showPassword.oldPassword, newPassword: !showPassword.newPassword, confirmNewPassword: showPassword.confirmNewPassword });
    } else {
      setShowPassword({ oldPassword: showPassword.oldPassword, newPassword: showPassword.newPassword, confirmNewPassword: !showPassword.confirmNewPassword });
    }
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setPasswordChangeData({
      ...passwordChangeData,
      [name]: value,
    });
  };

  const validatePassword = (pwd) => {
    const lengthCheck = pwd.length >= 8;
    const uppercaseCheck = /[A-Z]/.test(pwd);
    const lowercaseCheck = /[a-z]/.test(pwd);
    const numeralCheck = /[0-9]/.test(pwd);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);

    let isValid = lengthCheck && uppercaseCheck && lowercaseCheck && numeralCheck && specialCharCheck;
    if (!isValid) {
      toast.warning("Password is not valid. Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a numeral, and a special character.");
    }
    return isValid;
  };

  const handleChangePassword = async () => {
    const userDtl = getUserDetails();
    if (validatePassword(passwordChangeData.newPassword)) {
      if (passwordChangeData.newPassword === passwordChangeData.confirmNewPassword) {
        setLoading(true);
        let resp = await changePassword(passwordChangeData, userDtl.token);
        setLoading(false);
        if (resp.error === false) {
          toast.success(resp.data);
          if(userDtl.user.need_password_change === true){
            userDtl.user.need_password_change = false;
            navigate('/learner/dashboard');
            setUserDetails(userDtl);
            storeUserDetails(userDtl);
          }
          setPasswordChangeData({ oldPassword: '', newPassword: '', confirmNewPassword: '' });
        } else {
          toast.error(resp.data);
        }
      } else {
        toast.error("Password is not matching!");
      }
    }

  };

  const cancel = async () => {
    const userDtl = getUserDetails();
    if(userDtl.user.need_password_change === true){
      const data = {col_name: "need_password_change", value: false}
      let resp = await updateUserCol(data, userDtl.token);
      if(resp.error === true){
        console.log("error while updating need password change!");

      }else{
        userDtl.user.need_password_change = false;
        navigate('/learner/dashboard');
        setUserDetails(userDtl);
        storeUserDetails(userDtl);
      }
    }else{
      navigate('/learner/dashboard');
    }
  }

  return (
    <div>
      {loading ? (
        <div className="spinner-container">
          <img src='images/loading.gif' alt='GIF' />
        </div>
      ) : (
        <div className='resetpass-wrap flex flex-col justify-center items-center px-6'>
          <h3 className='text-2xl text-secondary text-center inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Change Password</h3>
          {userDetails.user.need_password_change ? (
                <div className='form-field mb-5'>
                  <label className='block mb-2 lg:mb-4 2xl:mb-5 text-3xl lg:text-3xl 2xl:text-3xl inter-500 text-secondary'>Please change your password, Click on cancel button if you want to keep same password!</label>
                </div>
              ) : (
                null
              )}
          <div className='md:w-96'>
            <form action="">

              <div className='form-field mb-5'>
                <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Previous Password <span className='text-[#f00]'>*</span></label>
                <div className='relative'>
                  <input type={showPassword.oldPassword ? 'text' : 'password'} name="oldPassword" value={passwordChangeData.oldPassword} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required />
                  <button onClick={() => handleShowPassword('oldPassword')} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                    <img className={showPassword.oldPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.oldPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide' />
                  </button>
                </div>
              </div>
              <div className='form-field mb-5'>
                <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>New Password <span className='text-[#f00]'>*</span></label>
                <div className='relative'>
                  <input type={showPassword.newPassword ? 'text' : 'password'} name="newPassword" value={passwordChangeData.newPassword} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required />
                  <button onClick={() => handleShowPassword('newPassword')} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                    <img className={showPassword.newPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.newPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide' />
                  </button>
                </div>
                <p className='text-[10px] text-gray inter-400 pt-1 leading-none'>Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a numeral, and a special character.</p>
              </div>
              <div className='form-field'>
                <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Confirm Password <span className='text-[#f00]'>*</span></label>
                <div className='relative'>
                  <input type={showPassword.confirmNewPassword ? 'text' : 'password'} name="confirmNewPassword" value={passwordChangeData.confirmNewPassword} onChange={handleValueChange} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required />
                  <button onClick={() => handleShowPassword('confirmNewPassword')} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                    <img className={showPassword.confirmNewPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.confirmNewPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide' />
                  </button>
                </div>
              </div>
              <div className='flex items-center mt-8 2xl:mt-11'>
                <button type="button" onClick={() => handleChangePassword()} className='bg-primary hover:bg-tertiary w-full md:w-auto flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Password</button>
              </div>
              <div className='flex items-center mt-8 2xl:mt-11'>
                <button type="button" onClick={() => cancel()} className='bg-secondary hover:bg-tertiary w-full md:w-auto flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )
      }
    </div>
  );
}