import React, {useRef, useState, useEffect} from 'react';
import '../spinner.css';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode, EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, logout, storeUserDetails } from '../services/UserService';
import { getAssignedCoursesData, getUserCoursesAndModulesAndLearningsData } from '../services/LearningService';
import { getNumberSuffix } from '../services/Utils';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swal from 'sweetalert2';
import 'animate.css';
import { learnerManualUrl } from '../utility/constants';
import { learnerTeacherIntroVideo } from '../utility/constants';

export default function LearnerDashboard() {
    gsap.registerPlugin(useGSAP,ScrollTrigger);
    const [bounds, setBounds] = useState({});
    const ccRefs = useRef([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [learnings, setLearnings] = useState({recentlyCompleted: {}, ongoing: {}, upcoming: {}, courses: []});
    const [overview, setOverview] = useState({assessmentCompleted: 0, totalCompleted: 0, score: 0, totalModules: 0, totalCourse: 0, totalStarted: 0});
    const [firstCourse, setFirstCourse] = useState({course_name: "", module_seq_no: 0, module_name: "", module_code: ""});
    const [selectedCourse, setSelectedCourse] = useState({});
    const [shakeActive, setShakeActive] = useState(true);
    useEffect(() => {
        const userDtl = getUserDetails();
        if(userDtl.token === undefined){
          navigate('/login');
        } else if(userDtl.user.need_password_change === true){
          navigate('/learner/passwordreset');
        } else if(userDtl.user.is_first_time_login === true){
          navigate('/learnerguidedtour');
        } else{
            getAssignedCourses();
            let showIntroVideo = sessionStorage.getItem('showIntroVideo');
            if(showIntroVideo !== null){
              Swal.fire({
                title: "Introduction",
                html: 
                `                        
                <video autoPlay controls>
                    <source src=${learnerTeacherIntroVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                ` ,
                width: '80%',
                height: '50%',
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                },
                showConfirmButton: true,
                confirmButtonText: "Close",
                allowOutsideClick: false
              });
              sessionStorage.removeItem('showIntroVideo');
            }else {
              setUserDetails(userDtl);
            }
        }
        const timer = setTimeout(() => {
          setShakeActive(false);
        }, 10000);
        return () => clearTimeout(timer);
    },[]);

    useEffect(() => {
      return () => {
        ccRefs.current.forEach((ccRef, index) => {
          if (ccRef) {
            ccRef.removeEventListener('mouseenter', handleMouseEnter(index));
            ccRef.removeEventListener('mouseleave', handleMouseLeave(index));
          }
        });
        document.removeEventListener('mousemove', rotateToMouse);
      };
    }, []);

    const rotateToMouse = (e) => {
      ccRefs.current.forEach((ccRef) => {
        if (ccRef && bounds[ccRef.dataset.index]) {
          const { x, y, width, height } = bounds[ccRef.dataset.index];
          const mouseX = e.clientX;
          const mouseY = e.clientY;
          const leftX = mouseX - x;
          const topY = mouseY - y;
          const center = {
            x: leftX - width / 2,
            y: topY - height / 2,
          };
          const distance = Math.sqrt(center.x ** 2 + center.y ** 2);
  
          // Apply transformations
          ccRef.style.transform = `
            scale3d(1.07, 1.07, 1.07)
            rotate3d(
              ${center.y / 100},
              ${-center.x / 100},
              0,
              ${Math.log(distance) * 2}deg
            )
          `;
  
          const glowElement = ccRef.querySelector('.glow');
          if (glowElement) {
            glowElement.style.backgroundImage = `
              radial-gradient(
                circle at
                ${center.x * 2 + width / 2}px
                ${center.y * 2 + height / 2}px,
                #ffffff55,
                #0000000f
              )
            `;
          }
        }
      });
    };

    const handleMouseEnter = (index) => {
        if (ccRefs.current[index]) {
          const cardRect = ccRefs.current[index].getBoundingClientRect();
          setBounds((prevBounds) => ({
            ...prevBounds,
            [index]: cardRect,
          }));
          document.addEventListener('mousemove', rotateToMouse);
        }
    };
  
    const handleMouseLeave = (index) => {
      return () => {
        if (ccRefs.current[index]) {
          document.removeEventListener('mousemove', rotateToMouse);
          ccRefs.current[index].style.transform = '';
          ccRefs.current[index].style.background = '';
        }
      };
    };
  

    const getAssignedCourses = async () => {
      const userDetails = getUserDetails();
      setLoading(true);
      const assignedCourses = await getAssignedCoursesData(userDetails);
      if(assignedCourses.error){
        toast.error(assignedCourses.data);
      }else{
        let course_ids = [];
        for(let i=0; i < assignedCourses.data.length; i++){
          course_ids.push(assignedCourses.data[i].course_id);
        }
        await getUserCoursesAndModulesAndLearnings(course_ids);
      }
      setLoading(false);
    };
    const getUserCoursesAndModulesAndLearnings = async (course_ids) => {
      const userDetails = getUserDetails();
      const assignedCourses = await getUserCoursesAndModulesAndLearningsData(userDetails.token, course_ids);
      if(assignedCourses.error){
        toast.error(assignedCourses.data);
        setLearnings({recentlyCompleted: {}, ongoing: {}, upcoming: {}, courses: []});
      }else{
        let data = assignedCourses.data;
        data.courses = groupModuleByCourse(assignedCourses.data.courses, course_ids);
        let overviewObj = {assessmentCompleted: 0, totalCompleted: 0, score: 0, totalModules: 0, totalCourse: 0, totalStarted: 0};
        let firstCourseObj = {course_name: "", module_seq_no: 0, module_name: "", module_code: ""};
        for(let i=0; i<data.courses.length; i++){
          if(i==0){
            firstCourseObj.course_name = data.courses[i].course_name;
          }
          data.courses[i]["completed"] = 0;
          overviewObj.totalCourse = overviewObj.totalCourse + 1;
          for(let j=0; j<data.courses[i].modules.length; j++){
            if(i==0 && j==0){
              firstCourseObj.module_seq_no = data.courses[i].modules[j].module_seq_no;
              firstCourseObj.module_name = data.courses[i].modules[j].module_name;
              firstCourseObj.module_code = data.courses[i].modules[j].module_code;

            }
            overviewObj.totalModules = overviewObj.totalModules + 1;
            if(data.courses[i].modules[j].status === 'COMPLETED'){
              data.courses[i]["completed"]  = data.courses[i]["completed"] + 1;
              overviewObj.totalCompleted = overviewObj.totalCompleted + 1;
            }
            if(data.courses[i].modules[j].status === 'STARTED'){
              overviewObj.totalStarted = overviewObj.totalStarted + 1;
            }
            if(data.courses[i].modules[j].status === 'COMPLETED' && data.courses[i].modules[j].module_type === 'ASSESSMENT'){
              overviewObj.assessmentCompleted = overviewObj.assessmentCompleted + 1;
            }
          }
        }
        overviewObj.score = Math.ceil(overviewObj.totalCompleted * 100 / overviewObj.totalModules);
        setFirstCourse(firstCourseObj);
        setOverview(overviewObj);
        setLearnings(data);
      }
    };
    const openLearningPage = async (course, module) => {
      sessionStorage.setItem('modules', JSON.stringify(learnings));
      let currentModule = Object.assign(module);
      currentModule['course_id'] = course.course_id;
      currentModule['course_code'] = course.course_code;
      currentModule['course_name'] = course.course_name;
      currentModule['course_desc'] = course.course_desc;
      currentModule['color'] = course.color;

      if(module.module_type == 'ASSESSMENT'){
        sessionStorage.setItem("currentModule", JSON.stringify(currentModule));
        navigate('/learner/assessment');
      }else if(module.module_type == 'THEORY'){
        sessionStorage.setItem("currentModule", JSON.stringify(currentModule));
        navigate('/learner/theory');
      }
    };
    const groupModuleByCourse = (data, course_ids) => {
        let courseMap = {};
        for(let i = 0; i < data.length;i++){
          let courseAndModule = Object.assign(data[i]);
          if(courseMap[courseAndModule.course_id] === undefined){
            courseMap[courseAndModule.course_id] = {course_id: courseAndModule.course_id, course_name: courseAndModule.course_name, course_code: courseAndModule.course_code, course_desc: courseAndModule.course_desc, color: courseAndModule.color, modules: []}
          }
          courseMap[courseAndModule.course_id]["modules"].push({
            module_id: courseAndModule.module_id,
            pedagogy_count: courseAndModule.pedagogy_count,
            module_code: courseAndModule.module_code,
            module_name: courseAndModule.module_name,
            module_desc: courseAndModule.module_desc,
            module_type: courseAndModule.module_type,
            module_seq_no: courseAndModule.module_seq_no,
            learning_pedagogy_count: courseAndModule.learning_pedagogy_count === null || courseAndModule.learning_pedagogy_count === undefined ? 0 : courseAndModule.learning_pedagogy_count,
            status: courseAndModule.status,
          });
        }
        let courseList = Object.values(courseMap);
        const mappedData = course_ids.map(id => courseList.find(item => parseInt(item.course_id) === parseInt(id)));
        const sortedData = mappedData.filter(item => item);
        return sortedData;
    };
    const onClickCardStack = (value) => {
      setSelectedCourse(value);
    };
    const openHelp = async () => {
      window.open(learnerManualUrl, '_blank');
    }

    return (
        <div className='overflow-hidden'>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
                {/* <div className="spinner"></div> */}
            </div>
          ) : (
            <div className='dash-container pt-10'>
              <h3 className='text-2xl text-black pacifico-regular text-center mb-3 lg:hidden'>Welcome {userDetails.user.first_name}!</h3>
              {/* Show this when new user comes first time start*/}
              { (learnings.ongoing.course_id === undefined) && (learnings.recentlyCompleted.course_id === undefined)? (
                <div className='first-course-row flex flex-wrap md:flex-nowrap items-center justify-center gap-x-20 lg:pl-12 pl-6 pr-6 lg:pr-0'>
                  
                  <div className='w-full lg:w-1/2 rounded-3xl shadow-md bg-gradient-to-r from-[#6E3174] to-[#9831A3] lg:p-14 p-8 mb-10 md:mb-0'>
                    <div>
                    <h3 className='text-2xl md:text-xl lg:text-3xl inter-700 text-white text-center mb-4 lg:mb-6'>Start Your First Course</h3>
                      <div className='flex rounded-3xl border-2 border-dashed border-[#F9EA98] lg:p-6 p-4'>
                        <div className='w-1/2 xl:pr-5'>
                          <h4 className='text-base leading-5 lg:text-xl 2xl:text-2xl lg:leading-7 text-white inter-700'>{firstCourse.course_name}</h4>
                        </div>
                        <div className='w-1/2'>
                          <p className='text-sm lg:text-base text-[#F9EA98] inter-700'>{firstCourse.module_seq_no} / {firstCourse.module_code}</p>
                          <p className='text-sm lg:text-base text-white inter-500'>{firstCourse.module_name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 pl-10 lg:pl-0'>
                    <div className='plan-dtl flex items-center lg:mb-10 mb-3'>
                      <div className='plan-icon'>
                        <img className='w-8 lg:w-11' src='../images/medal.svg' alt='medal logo'/>
                      </div>
                      <div className='plan-text'>
                        <p className='text-2xl lg:text-3xl inter-400 text-secondary lg:ml-4 ml-2'>{userDetails.user.subscription_code} Plan</p>
                      </div>
                    </div>
                    <div className='plan-courses lg:mb-10 mb-3'>
                      <div className='plan-dtl flex items-center mb-3'>
                        <div className='plan-icon w-8 lg:w-11 text-center'>
                          <img className='w-5 lg:w-7 mx-auto' src='../images/note.svg' alt='note logo'/>
                        </div>
                        <div className='plan-text'>
                          <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'><span className='inter-700'>{overview.totalCourse}</span> Technology</p>
                        </div>
                      </div>
                      <div className='plan-dtl flex items-center'>
                        <div className='plan-icon w-8 lg:w-11 text-center'>
                          <img className='w-5 lg:w-7 mx-auto' src='../images/book.svg' alt='book logo'/>
                        </div>
                        <div className='plan-text'>
                          <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'><span className='inter-700'>{overview.totalModules}</span> Module</p>
                        </div>
                      </div>
                    </div>
                    <div className='plan-courses'>
                      <div className='plan-dtl flex items-center mb-3'>
                        <div className='plan-icon w-8 lg:w-11 text-center'>
                          <img className='w-5 lg:w-7 mx-auto' src='../images/book.svg' alt='book logo'/>
                        </div>
                        <div className='plan-text'>
                          <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'>{overview.totalStarted} Module Started</p>
                        </div>
                      </div>
                      {/* <div className='plan-dtl flex items-center'>
                        <div className='plan-icon w-8 lg:w-11 text-center'>
                          <img className='w-5 lg:w-7 mx-auto' src='../images/book.svg' alt='book logo'/>
                        </div>
                        <div className='plan-text'>
                          <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'>0 Courses Started</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : (null)}
              {/* Show this when new user comes first time end*/}
              {/* Show this when user comes after completing course start*/}
              { (learnings.ongoing.course_id !== undefined) || (learnings.recentlyCompleted.course_id !== undefined)? (
                  <div>
                    <div className='flex flex-wrap md:flex-nowrap items-center gap-x-10 md:gap-x-4 lg:gap-x-6 xl:gap-x-10 lg:px-12 md:px-6 px-0 overflow-hidden'>
                      <div className='md:w-1/4 w-full md:text-left text-center mb-8 md:mb-0 animate__animated animate__fadeInLeft animate__delay-1s"'>
                        {/* <p className='text-base inter-700 text-[#366494]'>Congrats!</p> */}
                        <p className='text-xl inter-400 text-black'>Your Overall Progress is</p>
                        <h2 className='text-4xl inter-700 text-[#6E3174] lg:mb-5 mb-4'>{overview.score}%</h2>
                        <p className='text-base md:text-xs lg:text-base inter-400 text-black'>Modules Completed: <span className='font-bold'>{(overview.totalCompleted !== undefined && overview.totalCompleted!==null ? overview.totalCompleted : 0) - (overview.assessmentCompleted !== undefined && overview.assessmentCompleted!==null ? overview.assessmentCompleted : 0)}</span></p>
                        <p className='text-base md:text-xs lg:text-base inter-400 text-black'>Assessments Completed: <span className='font-bold'>{overview.assessmentCompleted}</span></p>
                      </div>
                      <div className='lrnr-dash-first-cards flex justify-between md:w-3/4 flex-nowrap lg:gap-x-6 xl:gap-x-10 2xl:gap-x-12 md:gap-x-0 gap-x-4 overflow-auto md:p-2.5 py-2.5 pl-6 pr-6'>
                        <div className='dash-card-three max-w-52 lg:max-w-none md:w-[31%] lg:w-1/3 2xl:w-72 flex flex-col justify-between bg-gradient-to-br from-[#366494] to-[#58C181] rounded-3xl md:rounded-2xl lg:rounded-3xl lg:py-5 xl:py-8 py-5 md:px-4 lg:px-5 xl:px-8 px-5 h-52 md:h-44 lg:min-h-52 xl:min-h-64 animate__animated animate__fadeInUp animate__delay-1s'>
                          <div className='course-icon flex'>
                            <img className='lg:w-6 h-6 2xl:w-8 hidden' src='../images/box-line.svg' alt='box line'/>
                            <img className='lg:w-6 h-6 2xl:w-8 hidden' src='../images/book-w.svg' alt='book'/>
                            <img className='lg:w-6 h-6 2xl:w-8' src='../images/greentick.svg' alt='greentick'/>
                            <p className='text-base inter-700 text-white pl-3'>Completed</p>
                          </div>
                          <div>
                            <h2 className='text-2xl md:text-xl lg:text-2xl 2xl:text-3xl md:leading-5 lg:leading-8 inter-700 text-white'>{learnings.recentlyCompleted.course_id !== undefined ? learnings.recentlyCompleted.course_name : "None"}</h2>
                          </div>
                          <div>
                            <p className='text-base inter-300 text-white'>Module</p>
                            <p className='text-xl inter-600 text-white'>{learnings.recentlyCompleted.module_seq_no !== undefined ? learnings.recentlyCompleted.module_seq_no + getNumberSuffix(parseInt(learnings.recentlyCompleted.module_seq_no)) : 0}</p>
                          </div>
                        </div>
                        <div className='dash-card-three max-w-52 lg:max-w-none md:w-[31%] lg:w-1/3 2xl:w-72 flex flex-col justify-between bg-gradient-to-br from-[#ffb600] to-[#eab740] rounded-3xl md:rounded-2xl lg:rounded-3xl lg:py-5 xl:py-8 py-5 md:px-4 lg:px-5 xl:px-8 px-5 h-52 md:h-44 lg:min-h-52 xl:min-h-64 animate__animated animate__fadeInUp animate__delay-2s'>
                          <div className='course-icon flex'>
                            <img className='lg:w-6 h-6 2xl:w-8' src='../images/box-line.svg' alt='box line'/>
                            <img className='lg:w-6 h-6 2xl:w-8 hidden' src='../images/book-w.svg' alt='book'/>
                            <img className='lg:w-6 h-6 2xl:w-8 hidden' src='../images/greentick.svg' alt='greentick'/>
                            <p className='text-base inter-700 text-white pl-3'>Ongoing</p>
                          </div>
                          <div>
                            <h2 className='text-2xl md:text-xl lg:text-2xl 2xl:text-3xl md:leading-5 lg:leading-8 inter-700 text-white'>{learnings.ongoing.course_id !== undefined ? learnings.ongoing.course_name : "None"}</h2>
                          </div>
                          <div>
                            <p className='text-base inter-300 text-white'>Module</p>
                            <p className='text-xl inter-600 text-white'>{learnings.ongoing.module_seq_no !== undefined ? learnings.ongoing.module_seq_no + getNumberSuffix(parseInt(learnings.ongoing.module_seq_no)) : 0}</p>
                          </div>
                        </div>
                        <div className='dash-card-three max-w-52 lg:max-w-none md:w-[31%] lg:w-1/3 2xl:w-72 flex flex-col justify-between bg-gradient-to-br from-[#dd6600] to-[#ff9539] rounded-3xl md:rounded-2xl lg:rounded-3xl lg:py-5 xl:py-8 py-5 md:px-4 lg:px-5 xl:px-8 px-5 h-52 md:h-44 lg:min-h-52 xl:min-h-64 animate__animated animate__fadeInUp animate__delay-3s'>
                          <div className='course-icon flex'>
                            <img className='lg:w-6 h-6 2xl:w-8 hidden' src='../images/box-line.svg' alt='box line'/>
                            <img className='lg:w-6 h-6 2xl:w-8' src='../images/book-w.svg' alt='book'/>
                            <img className='lg:w-6 h-6 2xl:w-8 hidden' src='../images/greentick.svg' alt='greentick'/>
                            <p className='text-base inter-700 text-white pl-3'>Upcoming</p>
                          </div>
                          <div>
                            <h2 className='text-2xl md:text-xl lg:text-2xl 2xl:text-3xl md:leading-5 lg:leading-8 inter-700 text-white'>{learnings.upcoming.course_id !== undefined ? learnings.upcoming.course_name : "None"}</h2>
                          </div>
                          <div>
                            <p className='text-base inter-300 text-white'>Module</p>
                            <p className='text-xl inter-600 text-white'>{learnings.upcoming.module_seq_no !== undefined ? learnings.upcoming.module_seq_no + getNumberSuffix(parseInt(learnings.upcoming.module_seq_no)) : 0}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              ) : (null)}    
              {/* Show this when user comes after completing course end*/}
              <div className='dash-course-wrap mt-12 md:mt-8 lg-mt-12'>
                <div className='dash-course-heading lg:px-12 px-6 border-b-2 border-[#D0D0D0] pb-2'>
                  <h3 className='text-lg lg:text-2xl inter-700 text-black'>My Technology</h3>
                  {/* <a onClick={() => navigate('/learner/courses')} className='cursor-pointer course-btn-yellow bg-[#E76457] hover:bg-black flex items-center justify-center h-8 2xl:h-10 text-white inter-600 text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow px-10 py-5'>View All My Technology</a> */}
                </div>
                <div className='course-slider-wrap mt-5 lg:pl-12 pl-0 pr-0 lg:pr-0 pb-12'>
                {selectedCourse !== undefined && selectedCourse !== null && selectedCourse.course_name !== undefined ? 
                (  
                  <div className=''>
                        <h4 className='text-lg inter-600 text-black lg:pl-0 pl-6 lg:pr-0 pr-6 md:-mb-8 mb-3'>{selectedCourse.course_name}</h4>
                        <div className='course-slider-row mb-6 flex overflow-hidden'>
                          <div className='course-slider overflow-hidden md:pt-12 md:pl-6 lg:pl-0 w-full'>
                            <Swiper
                              spaceBetween={10}
                              slidesPerView={1.5}
                              freeMode={true}
                              navigation={true}
                              modules={[Navigation, FreeMode]}
                              breakpoints={{
                                640: {
                                  slidesPerView:1.5,
                                  spaceBetween:10,
                                },
                                768: {
                                  slidesPerView:3.5,
                                  spaceBetween:10,
                                },
                                1024: {
                                  slidesPerView:4.5,
                                  spaceBetween:20,
                                },
                                1400: {
                                  slidesPerView:5.5,
                                },
                              }}
                              className='overflow-visible'
                            >
                              <SwiperSlide>
                              <div style={{ backgroundColor: `${selectedCourse.color}` }} className={`course-card flex flex-col justify-between min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:pl-5 xl:pl-7 pl-5 lg:pr-5 xl:pr-10 pr-5`}>
                                <h2 className='text-2xl lg:text-2xl 2xl:text-3xl text-white inter-700 mb-4'>{selectedCourse.course_name}</h2>
                                <div>
                                  <p className='lg:text-base 2xl:text-lg text-white inter-600'>{selectedCourse.modules.length} Modules</p>
                                  <p className='lg:text-base 2xl:text-lg text-white inter-600'>{selectedCourse.completed} Completed</p>
                                </div>
                              </div>
                              </SwiperSlide>
                              {selectedCourse.modules.map((subValue, subIndex) => (
                                <SwiperSlide key={subIndex}>
                                  <div>
                                    <a style={{ backgroundColor: `${selectedCourse.color}` }} onClick={() => openLearningPage(selectedCourse, subValue)} className={`cursor-pointer course-card flex flex-col justify-between min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:pl-5 xl:pl-7 pl-5 lg:pr-5 xl:pr-10 pr-5 block hover:scale-105 hover:-translate-y-4 hover:shadow-lg transition-all duration-300 ease-in-out'`}>
                                      <div>
                                        <div className='course-icon mb-4'>
                                          {subValue.status === 'STARTED' ? (
                                            <img className='lg:w-6 h-6 2xl:w-8' src='../images/box-line.svg' alt='box line'/>
                                          ): (
                                            null
                                          )}
                                          {subValue.status === 'COMPLETED' || subValue.status===null ? (
                                            <img className='lg:w-6 h-6 2xl:w-8' src='../images/greentick.svg' alt='greentick'/>
                                          ): (
                                            null
                                          )}
                                          {subValue.status === undefined || subValue.status === 'NOT_STARTED' ? (
                                            <img className='lg:w-6 h-6 2xl:w-8' src='../images/book-w.svg' alt='book'/>
                                          ): (
                                            null
                                          )}
                                        </div>
                                        <div className='course-text mb-4'>
                                          <p className='lg:text-lg 2xl:text-xl lg:leading-6 text-white inter-600'>{subValue.module_seq_no}. {subValue.module_name}</p>
                                          {subValue.module_type !== 'ASSESSMENT' ? (
                                              <>
                                              {subValue.pedagogy_count === null || subValue.pedagogy_count === undefined ? (
                                                <>
                                                  <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>0 Pedagogies</p>
                                                  {/* <p className='lg:text-base 2xl:text-lg text-white inter-700 '>0% Completed</p> */}
                                                </>
                                              ) : (
                                                <>
                                                  <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>{subValue.pedagogy_count} Pedagogies</p>
                                                  {Math.ceil(parseInt(subValue.learning_pedagogy_count)*100/parseInt(subValue.pedagogy_count)) > 0 ? (
                                                    <p className='lg:text-base 2xl:text-lg text-white inter-700 '>{Math.min(Math.ceil(parseInt(subValue.learning_pedagogy_count)*100/parseInt(subValue.pedagogy_count)), 100)}% Completed</p>

                                                  ): (
                                                    null
                                                  )}
                                                </>
                                              )}
                                              </>
                                            ) : (
                                              null
                                            ) 
                                          }
                                          {/* <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>{subIndex+1} / {subValue.module_code}</p> */}
                                        </div>
                                      </div>
                                      <div className='course-btn-wrap'>
                                        {(subValue.status === undefined || subValue.status === 'NOT_STARTED') && subValue.module_type !== 'ASSESSMENT' ? (
                                          <div onClick={() => openLearningPage(selectedCourse, subValue)} className='course-btn-yellow bg-[#F9EA98] hover:bg-white flex items-center justify-center h-8 2xl:h-10 text-black inter-600 text-base md:text-sm lg:text-base  2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Start Learning</div>
                                        ): (null)}
                                        {(subValue.status === undefined || subValue.status === 'NOT_STARTED') && subValue.module_type === 'ASSESSMENT' ? (
                                          <div onClick={() => openLearningPage(selectedCourse, subValue)} className='course-btn-yellow bg-[#F9EA98] hover:bg-white flex items-center justify-center h-8 2xl:h-10 text-black inter-600 text-base md:text-sm lg:text-base  2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Take Test</div>
                                        ): (null)}
                                        {subValue.status === 'STARTED' && subValue.module_type !== 'ASSESSMENT' ? (
                                          <div onClick={() => openLearningPage(selectedCourse, subValue)} className='course-btn-green bg-[#58C181] hover:bg-black flex items-center justify-center h-8 2xl:h-10 text-white inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Continue Learning</div>
                                        ): (null)}
                                        {subValue.status === 'STARTED' && subValue.module_type === 'ASSESSMENT' ? (
                                          <div onClick={() => openLearningPage(selectedCourse, subValue)} className='course-btn-green bg-[#58C181] hover:bg-black flex items-center justify-center h-8 2xl:h-10 text-white inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Continue Test</div>
                                        ): (null)}
                                        
                                        {(subValue.status === 'COMPLETED' || subValue.status===null) && subValue.module_type === 'ASSESSMENT' ? (
                                          <div onClick={() => openLearningPage(selectedCourse, subValue)} className='course-btn-white bg-white hover:bg-[#F9EA98] flex items-center justify-center h-8 2xl:h-10 text-black inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Retake Test</div>
                                        ): (null)}
                                        {subValue.status === 'COMPLETED' && subValue.module_type !== 'ASSESSMENT' ? (
                                          <div onClick={() => openLearningPage(selectedCourse, subValue)} className='course-btn-white bg-white hover:bg-[#F9EA98] flex items-center justify-center h-8 2xl:h-10 text-black inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Learn Again</div>
                                        ): (null)}
                                        </div>
                                    </a>  
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                  </div>
                ) : (
                  null
                )}

                <div className='stack-cards-wrap'>
                      <div className=''>
                        <div className='course-slider-row mb-6 flex justify-center'>
                          <div className='course-slider md:pt-12 md:pl-6 md:pr-6 px-10 lg:px-16 w-full md:w-3/6 lg:w-2/6'>
                            <Swiper
                              effect={'cards'}
                              grabCursor={false}
                              navigation={true}
                              modules={[Navigation, EffectCards]}
                              className='overflow-visible'
                            >
                              {learnings.courses.map((value, index) => (
                              <SwiperSlide key={index} onClick={() => onClickCardStack(value)} className="course-sngl overflow-visible">
                                <div ref={(el) => (ccRefs.current[index] = el)} data-index={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)} style={{ backgroundColor: `${value.color}` }} className={`cc course-card flex flex-col justify-between md:w-72 min-h-72 2xl:min-h-80 2xl:w-80 rounded-3xl py-6 lg:pl-5 xl:pl-7 pl-5 lg:pr-5 xl:pr-10 pr-5`}>
                                  <h2 className='text-2xl lg:text-2xl 2xl:text-3xl text-white inter-700 mb-4'>{value.course_name}</h2>
                                  <div>
                                    <p className='lg:text-base 2xl:text-lg text-white inter-600'>{value.modules.length} Modules</p>
                                    <p className='lg:text-base 2xl:text-lg text-white inter-600'>{value.completed} Completed</p>
                                  </div>
                                  <div className="glow"></div>
                                </div>
                              </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                </div>
                </div>
              </div>
              <div className={shakeActive? 'manual-icon-box bounce2 fixed bottom-8 right-8' : 'manual-icon-box fixed bottom-8 right-8'}>
                <a onClick={() => openHelp()} className='manual-icon bg-white hover:bg-[#C13F63] rounded-full flex items-center justify-center w-12 h-12 button-shadow transition duration-300 cursor-pointer'>
                    <img className='transition duration-300' src='../images/help-icon.svg' alt='help'/>
                </a>
              </div>
            </div>
          )}
        </div>
    );
}