import React, {useEffect, useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addContactUs } from '../services/UserService';

export default function ThankYou() {
    
    const navigate = useNavigate();

    const routeToLogin = async () => {
        navigate('/login');
    };

    return (
        <div>
            <div className='h-screen flex items-center bg-center bg-no-repeat bg-cover' style={{backgroundImage : "url('../../images/bg-tour.webp')"}}>
                <div className='quote-header fixed top-0 w-full py-4 px-5 lg:px-10 border-b border-[#fff]'>
                    <div>
                        <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer w-20 md:w-28 brightness-0' src='../images/qlite-logo.webp' alt='qlite logo'/>
                    </div>
                </div>
                <div className='qote-bnr-content px-5 lg:px-10'>
                    <h2 className='text-5xl text-black inter-700 mb-4'>Thank You</h2>
                    <p className='text-lg text-black inter-400'>Thank you for contacting us. We will reach you soon.</p>
                    <div className='w-full mt-12'>
                        <button onClick={routeToLogin} type="button" className='w-full md:w-auto  bg-primary hover:bg-secondary flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}